import React, { useEffect, useState } from "react";
import Title from "@components/common/Title";
// import { Link } from "gatsby";
import { IoRefreshCircleOutline } from "react-icons/io5";
import { v4 as uuid } from "uuid";
import dayjs from "dayjs";
import axios from "axios";
import relativeTime from "dayjs/plugin/relativeTime";
import { RenderPostAlt } from "../../templates/blog/components/renderPost";
import "dayjs/locale/es";
dayjs.extend(relativeTime);
dayjs.locale("es");

const Component = ({ className }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const posts = await getPosts({ page: 1, pageSize: 3 });
                setData(posts);
                setLoading(false);
            } catch (err) {
                console.error(err);
            }
        }
        fetchData();
    }, []);

    return (
        <section className={className}>
            <div className="container lg:px-0 py-20 ">
                <Title className="text-center">Últimas entradas de nuestro blog</Title>
                <Loading loading={loading} />
                <RenderPosts data={data} />
            </div>
        </section>
    );
};

const RenderPosts = ({ data }) => {
    if (!data) return null;
    const posts = data?.data || [];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10 gap-10">
            {posts.map(post => (
                <RenderPostAlt key={uuid()} data={post} />
            ))}
        </div>
    );
};

const Loading = ({ loading }) => {
    return (
        <>
            {loading && (
                <IoRefreshCircleOutline className="duration-300 animate-spin w-10 h-10 text-gray-500 mx-auto" />
            )}
        </>
    );
};

const getPosts = async ({ page, pageSize }) => {
    try {
        const url = `/.netlify/functions/posts`;
        const params = {
            page,
            pageSize,
        };

        const options = {
            headers: {
                "Content-Type": "application/json",
                "Strapi-Response-Format": "v4",
            },
        };

        const { data } = await axios.post(url, params, options);

        return data?.posts;
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default Component;
