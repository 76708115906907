import React from "react";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import BackgroundImage from "../common/CloudinaryImage";
import { Link } from "gatsby";

const AboutUs = props => {
    return (
        <>
            <section className={`${props.className}`}>
                <div className="container lg:px-0 py-20">
                    <Title>¿Quiénes somos?</Title>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                        <Text className="text-justify">
                            Somos un equipo de profesionales, con{" "}
                            <strong>más de 20 años de experiencia</strong> en nuestras áreas. Desde
                            2014 damos apoyo integral a las personas que quieren{" "}
                            <strong>preparar oposiciones</strong>. Somos desarrolladores de
                            aplicaciones y expertos en oposiciones para acceder a los{" "}
                            <i>Cuerpos de Seguridad del Estado</i> y{" "}
                            <i>la Administración Pública</i>.
                            <br />
                            <br />
                            Nuestros clientes nos eligen porque desarrollamos aplicaciones
                            verdaderamente útiles para el usuario, desde el conocimiento y
                            background de las oposiciones. Además, mantenemos una atención al
                            cliente excelente y generamos comunidad alrededor de las aplicaciones,
                            compartiendo contenido útil.
                            <div className="mt-10">
                                Para consultar más información sobre nosotros haz clic{" "}
                                <Link
                                    title="¿Quién somos?"
                                    to="/nosotros"
                                    className="border-b text-ccyan-dark hover:text-ccyan-darker duration-300"
                                >
                                    aquí
                                </Link>
                                .
                            </div>
                        </Text>

                        <div className="overflow-hidden rounded shadow">
                            <BackgroundImage
                                className="h-full bg-gray-200"
                                fadeIn={true}
                                filename="bgs/bgAboutUs"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default AboutUs;
