import React, { useState } from "react";
import { Link } from "gatsby";
import BackgroundImage from "@components/common/CloudinaryImage";

const items = [
    {
        id: 1,
        title: "Aplicaciones",
        link: "/aplicaciones",
        image: "/bgs/bgIndex",
    },
    {
        id: 2,
        title: "Libros",
        link: "/tienda/libros",
        image: "/bgs/bgBooks",
    },
    {
        id: 3,
        title: "Pruebas físicas",
        link: "/tienda/fisicas",
        image: "/bgs/bgSports",
    },
    {
        id: 4,
        title: "Pruebas psicotécnicas",
        link: "/tienda/psicotecnicos",
        image: "/bgs/bgPsicos",
    },
    {
        id: 5,
        title: "Cursos Online",
        link: "/tienda/cursos-online",
        image: "/bgs/bgCursosOnline",
    },
    {
        id: 6,
        title: "Cursos Presenciales",
        link: "/tienda/cursos-presenciales",
        image: "/bgs/bgClassroom",
    },
    {
        id: 7,
        title: "Certificados",
        link: "/tienda/certificados",
        image: "/bgs/bgCertificados",
    },
];

const Component = props => {
    return (
        <div className="py-20">
            <section
                className={`container lg:px-0 shadow rounded overflow-hidden ${
                    props?.className ? props.className : ""
                }`}
            >
                <div className="flex flex-col lg:flex-row lg:h-116 gap-0">
                    {items &&
                        items.map(item => {
                            return <Item item={item} key={item.id} image={item.image} />;
                        })}
                </div>
            </section>
        </div>
    );
};

const Item = ({ item, image }) => {
    const [selected, setSelected] = useState(false);

    return (
        <Link
            to={item.link}
            title={item.title}
            key={item.id}
            className={`h-72 hover:h-96 md:h-96 hover:md:h-116 lg:h-auto lg:w-1/6 flex-grow shadow duration-300 lg:hover:w-4/6 lg:hover:h-auto overflow-hidden`}
            style={{ width: selected ? "66%" : null }}
            onMouseEnter={() => {
                setSelected(true);
            }}
            onMouseLeave={() => {
                setSelected(false);
            }}
        >
            <BackgroundImage filename={image} className="h-full bg-gray-200 w-full absolute z-0">
                <div
                    className={`absolute z-[1] bg-black top-0 left-0 w-full h-full flex duration-500 ${
                        selected ? "opacity-100 bg-opacity-50 " : "opacity-0 bg-opacity-100"
                    }`}
                >
                    <div className="mx-auto my-auto px-5">
                        <h1 className="uppercase text-white text-3xl font-bold font-montserrat text-center">
                            {item.title}
                        </h1>
                    </div>
                </div>
            </BackgroundImage>
        </Link>
    );
};

export default Component;
