import React from "react";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { FaHandsHelping } from "react-icons/fa";
import { AiFillGold } from "react-icons/ai";
import { BiCustomize } from "react-icons/bi";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { ImMobile2 } from "react-icons/im";
import { MdSystemUpdate } from "react-icons/md";
import BackgroundImage from "../common/CloudinaryImage";

const Why = props => {
    return (
        <>
            <section className={`${props.className}`}>
                <div className="container lg:px-0 py-20">
                    <Title>¿Por qué utilizar nuestras aplicaciones?</Title>
                    <Text className="text-justify mb-10">
                        Porque con nuestras aplicaciones encontrarás una forma diferente de preparar
                        tus oposiciones. Porque tienes todo lo que necesitas.
                    </Text>
                    <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                        <div className="h-56 md:h-full overflow-hidden rounded shadow">
                            <BackgroundImage className="h-full bg-gray-200" filename="bgs/bgWhy" />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                            <div>
                                <h3 className="font-bold uppercase text-lg text-gray-600 border-b-2 pb-1 mb-3">
                                    Apoyo integral
                                </h3>
                                <FaHandsHelping
                                    className="text-cpurple fill-current h-8 w-8 hidden md:block mb-2"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <Text className="text-justify text-sm">
                                    Te asesoramos y resolvemos tus dudas durante toda la oposición.
                                    Hemos sido y somos opositores y conocemos en profundidad todo el
                                    proceso de selección.
                                </Text>
                            </div>

                            <div>
                                <h3 className="font-bold uppercase text-lg text-gray-600 border-b-2 pb-1 mb-3">
                                    Actualización Constante
                                </h3>
                                <MdSystemUpdate
                                    className="text-cpurple fill-current h-8 w-8 hidden md:block mb-2"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <Text className="text-justify text-sm">
                                    Mantenemos nuestra base de datos actualizada y te informamos de
                                    todos los cambios que se producen tanto en la legislación como
                                    en los cambios que puedan suceder durante la oposición.
                                </Text>
                            </div>

                            <div>
                                <h3 className="font-bold uppercase text-lg text-gray-600 border-b-2 pb-1 mb-3">
                                    Ránkings
                                </h3>
                                <AiFillGold
                                    className="text-cpurple fill-current h-8 w-8 hidden md:block mb-2"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <Text className="text-justify text-sm">
                                    Comprueba tu evolución y ves escalando posiciones entre el resto
                                    de los usuarios hasta colocarte entre los mejores.
                                </Text>
                            </div>

                            <div>
                                <h3 className="font-bold uppercase text-lg text-gray-600 border-b-2 pb-1 mb-3">
                                    Test a tu medida
                                </h3>
                                <BiCustomize
                                    className="text-cpurple fill-current h-8 w-8 hidden md:block mb-2"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <Text className="text-justify text-sm">
                                    Podrás elegir temática, número de preguntas y modo de juego y
                                    practicar las más falladas, las más difíciles o modo aprendizaje
                                    entre otros.
                                </Text>
                            </div>

                            <div>
                                <h3 className="font-bold uppercase text-lg text-gray-600 border-b-2 pb-1 mb-3">
                                    Acceso a Redes Sociales
                                </h3>
                                <TiSocialFacebookCircular
                                    className="text-cpurple fill-current h-8 w-8 hidden md:block mb-2"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <Text className="text-justify text-sm">
                                    Podrás acceder a nuestras redes sociales desde la app, punto de
                                    encuentro para opositores, para estar al día de todas las
                                    novedades o enviarnos un correo electrónico.
                                </Text>
                            </div>

                            <div>
                                <h3 className="font-bold uppercase text-lg text-gray-600 border-b-2 pb-1 mb-3">
                                    Interfaz atractiva
                                </h3>
                                <ImMobile2
                                    className="text-cpurple fill-current h-8 w-8 hidden md:block mb-2"
                                    style={{ verticalAlign: "middle" }}
                                />
                                <Text className="text-justify text-sm">
                                    Un formato ágil. Una app visual e intuitiva, atractiva y fácil
                                    de usar. Una estética acorde con el espíritu de la app y de sus
                                    opositores.
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Why;
