import React from "react";
import Layout from "@components/layouts/Layout";
import Banner from "@components/common/Banner";
import Counters from "@components/sections/LazyCounters";
import Testimonials from "@components/sections/LazyTestimonials";
import AboutUs from "@components/sections/AboutUs";
import StoreSections from "@components/sections/StoreSections";
import Why from "@components/sections/Why";
import Blog from "@components/sections/Blog";

export default function Home({ location }) {
    return (
        <>
            <Layout
                location={location}
                seo={{
                    title: "Inicio",
                    description:
                        "Prepárate en tiempo récord. Con la máxima calidad ygarantía de éxito. Tu oposición a Mossos d'Esquadra, Guàrdia Urbana de Barcelona y Policías Locales.",
                }}
            >
                <Banner filename="bgs/bgAcademy7">
                    <h1 className="font-montserrat font-bold text-4xl sm:text-5xl lg:text-6xl text-shadow-lg text-white">
                        Academia Oposiciones a Policía
                    </h1>
                    <h2 className="text-white opacity-85 mt-2 font-montserrat leading-6 sm:leading-8 sm:text-xl lg:text-2xl lg:leading-10 text-shadow-lg">
                        Prepárate en tiempo récord. Con la máxima calidad y garantía de éxito. Tu
                        oposición a Mossos d'Esquadra, Guàrdia Urbana de Barcelona y Policías
                        Locales.
                    </h2>
                </Banner>

                <AboutUs className="bg-white" />

                {process.env.GATSBY_BLOG_ENABLED === "true" && (
                    <Blog className="bg-gray-100 bg-opacity-50" />
                )}

                <StoreSections />

                <Why className="bg-gray-100 bg-opacity-50" />

                <Counters />

                <Testimonials className="bg-gray-100 bg-opacity-50" />
            </Layout>
        </>
    );
}
