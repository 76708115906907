import React from "react";
import loadable from "@loadable/component";
import useNearScreen from "@components/hooks/useNearScreen";

const Counters = loadable(() => import("./Counters"));

export default function LazyCounters() {
    const { isNearScreen, fromRef } = useNearScreen("200px");

    return (
        <>
            <section ref={fromRef}>{isNearScreen ? <Counters /> : null}</section>
        </>
    );
}
